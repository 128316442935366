export interface StripeCreateResponse {
  status: string;
  amount: number;
  message: string;
  orderId: number;
  timestamp: string;
  clientSecret: string;
  paymentMethod: string;
  transactionId: string;
}
