import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseActionComponent } from '../../purchase-action/purchase-action.component';
import { NgIf } from '@angular/common';
import { PayuPaymentComponent } from './payu-payment.component';
import { PaymentEngineType } from '@app/interfaces';

@Component({
  standalone: true,
  templateUrl: './payu-payment.component.html',
  imports: [TranslateModule, PurchaseActionComponent, NgIf],
})
export class PayuOthersPaymentComponent extends PayuPaymentComponent {
  protected override paymentEngineType = PaymentEngineType.PayU2_otros;
}
