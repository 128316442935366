import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { ParametersModel } from '../models/parameters.model';
import { PaymentModel } from '../models';
import { Payment } from '../actions';

export const PAYMENT_SELECTION_STATE_TOKEN = new StateToken<ParametersModel>(
  'payment'
);

@State<PaymentModel>({
  name: PAYMENT_SELECTION_STATE_TOKEN,
  defaults: {
    amount: 0,
    orderId: 0,
    paying: false,
    stripe: {
      clientSecret: '',
    },
  },
})
@Injectable()
export class PaymentState {
  @Selector()
  static paying({ paying }: PaymentModel): boolean {
    return paying;
  }

  @Selector()
  static data(model: PaymentModel): PaymentModel {
    return model;
  }

  @Selector()
  static orderId({ orderId }: PaymentModel): number {
    return orderId;
  }

  @Selector()
  static stripeClientSecret(model: PaymentModel): string {
    return model.stripe.clientSecret;
  }

  @Action(Payment.SetOrderId)
  setPlans(ctx: StateContext<PaymentModel>, payload: Payment.SetOrderId) {
    ctx.patchState(payload);
  }

  @Action(Payment.SetPayingStatus)
  setPaying(ctx: StateContext<PaymentModel>, payload: Payment.SetPayingStatus) {
    ctx.patchState(payload);
  }

  @Action(Payment.SetStripePayment)
  setStripe(
    ctx: StateContext<PaymentModel>,
    { clientSecret, amount }: Payment.SetStripePayment
  ) {
    ctx.patchState({ amount, stripe: { clientSecret } });
  }
}
