export function convertObjectValuesToString<T extends Record<string, any>>(
  obj: T
): Record<keyof T, string> {
  const result: Record<keyof T, string> = {} as Record<keyof T, string>;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[key] = String(obj[key]);
    }
  }

  return result;
}

export function getTravelInsuranceRequestIdFromHashcode(
  hashcode: string
): number | null {
  try {
    const result = atob(hashcode).split('-').shift() ?? null;

    return result ? +result : null;
  } catch (err) {
    return null;
  }
}

export const toggleMainLoader = (hidde: boolean) => {
  if (hidde) {
    document.body.style.overflow = 'auto';
    document.getElementById('main-loader-container')?.classList.add('hidden');
  } else {
    document.body.style.overflow = 'hidden';
    document
      .getElementById('main-loader-container')
      ?.classList.remove('hidden');
  }
};

export const getWhatsappLink = () =>
  'https://api.whatsapp.com/send?phone=17868400472&text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20seguro%20de%20viaje';

export const scrollTop = () =>
  window.scroll({
    top: 80,
    left: 0,
  });
