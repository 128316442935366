import { Currency, Visa } from '../../interfaces';

export interface CheckoutModel {
  domain: string;
  isAdmin: boolean;
  currency: string;
  entityId: number;
  hashcode: string;
  currencies: Currency[];
  currentStep: number;
  detail: {
    price: number;
    origin: Visa;
    destiny: Visa;
    perksPrice: number;
    arrivalDate: string;
    travelLength: number;
    discountCode: string;
    departureDate: string;
    discountPercentage: number;
    priceWithoutModifiers: number;
  };
}

const DEFAULT_VISA = { id: 1, name: 'Estados Unidos' };
export const DEFAULT_CHECKOUT_MODEL: CheckoutModel = {
  domain: localStorage.getItem('spv_topLevelDomain') ?? 'om',
  isAdmin: false,
  entityId: 0,
  hashcode: '',
  currency: localStorage.getItem('spv_currency') ?? 'USD',
  currencies: [],
  currentStep: 0,
  detail: {
    price: 0,
    origin: Object.assign({}, DEFAULT_VISA),
    destiny: Object.assign({}, DEFAULT_VISA),
    perksPrice: 0,
    arrivalDate: '',
    travelLength: 0,
    discountCode: '',
    departureDate: '',
    discountPercentage: 0,
    priceWithoutModifiers: 0,
  },
};
