import { Component, OnInit } from '@angular/core';
import {
  DLocalPaymentMethod,
  DLocalPaymentMethodCode,
} from '@app/features/checkout/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseActionComponent } from '../../purchase-action/purchase-action.component';
import { NgFor, NgIf } from '@angular/common';
import { PaymentComponent } from '../base-payment.component';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { CheckoutState } from '@app/features/checkout/store';
import { PAYMENT_METHOD_CODES } from './paymentMethodCodes';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { PaymentEngineType } from '@app/interfaces';
import { finalize } from 'rxjs';
import { ErrorAlert } from '@app/helpers';

@Component({
  selector: 'app-d-local-cash-payment',
  templateUrl: './d-local-cash-payment.component.html',
  styleUrls: ['./d-local-cash-payment.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    PurchaseActionComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    NgIf,
    NgFor,
  ],
})
export class DLocalCashPaymentComponent
  extends PaymentComponent
  implements OnInit
{
  protected override paymentEngineType = PaymentEngineType.DLocal_Cash;

  public merchants: DLocalPaymentMethodCode[] = PAYMENT_METHOD_CODES;
  public merchant: DLocalPaymentMethodCode | null = null;
  public paymentMethod: DLocalPaymentMethod | null = null;
  public hiddeMerchantSelect = true;

  public form = new FormGroup({
    dni: new FormControl('', Validators.required),
    bank: new FormControl('', Validators.required),
    country: new FormControl(''),
    currency: new FormControl(''),
  });

  ngOnInit(): void {
    const tld = this.store.selectSnapshot(CheckoutState.domain);
    this.merchant =
      PAYMENT_METHOD_CODES.find(
        ({ alpha2Code }) =>
          alpha2Code == (tld == 'om' ? 'usa' : tld).toUpperCase()
      ) ?? null;
    this.hiddeMerchantSelect = !this.merchant;
    this.form.get('country')?.setValue(this.merchant?.alpha2Code ?? null);
    this.form.get('currency')?.setValue(this.merchant?.currency ?? null);
  }

  selectMerchant(merchant: DLocalPaymentMethodCode) {
    if (!merchant) return;

    this.merchant = merchant;
    this.form.patchValue({
      bank: '',
      country: merchant.alpha2Code,
      currency: merchant.currency,
    });
  }

  selectPaymentMethod(id: string) {
    this.paymentMethod =
      this.merchant?.methods.find((method) => method.id === id) ?? null;
  }

  checkout() {
    if (this.isPaying() || this.form.invalid) return;
    this.setPayingStatus(true);

    const form = this.form.value;
    const { discountCode } = this.store.selectSnapshot(CheckoutState.details);

    this._service
      .dlocalCreate({
        orderId: this.orderId(),
        country: form.country ?? 'USA',
        currency: form.currency ?? 'USD',
        requestId: this.orderId() == 0 ? this.entityId() : 0,
        paymentType: form.bank ?? 'EF',
        documentCash: form.dni ?? '',
        discountCode,
      })
      .pipe(finalize(() => this.setPayingStatus(false)))
      .subscribe({
        next: (result) => (location.href = result.redirectUrl),
        error: ({ error }) => ErrorAlert.fire({ text: error.error.message }),
      });
  }
}
