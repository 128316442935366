<div class="row mt-3">
  <div class="col-12">
    <p class="Gotham-bold fw-700 f-16 mb-0">
      {{ "Serás redireccionado al sitio web para pagar." | translate }}
    </p>
  </div>

  <div class="col-12">
    <app-purchase-action (onCheckout)="checkout()"></app-purchase-action>
  </div>
</div>
