import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseActionComponent } from '../../purchase-action/purchase-action.component';
import { NgIf } from '@angular/common';
import { CheckoutState } from '@app/features/checkout/store/states';
import { PaymentComponent } from '../base-payment.component';
import { PaymentEngineType } from '@app/interfaces';
import { finalize } from 'rxjs';
import { ErrorAlert } from '@app/helpers';

@Component({
  selector: 'app-payu-payment',
  standalone: true,
  templateUrl: './payu-payment.component.html',
  imports: [TranslateModule, PurchaseActionComponent, NgIf],
})
export class PayuPaymentComponent extends PaymentComponent {
  protected override paymentEngineType = PaymentEngineType.PayU;

  public checkout() {
    if (this.isPaying()) return;
    this.setPayingStatus(true);

    const hashcode = this.store.selectSnapshot(CheckoutState.hashcode);

    this._service
      .payuCreate({
        orderId: this.orderId(),
        requestId: this.orderId() == 0 ? this.entityId() : 0,
        responseUrl: `${origin}/cotizar/pago-pendiente/${hashcode}`,
      })
      .pipe(finalize(() => this.setPayingStatus(false)))
      .subscribe({
        next: (response) => {
          if (response.status) location.href = response.checkoutUrl;
        },
        error: ({ error }) => ErrorAlert.fire({ text: error.error.message }),
      });
  }
}
