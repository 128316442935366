export interface MercadoPagoCreateRequest {
  requestId: number;
  orderId: number;
  backUrl: BackUrl;
  discountCode: string;
}

interface BackUrl {
  success: string;
  pending: string;
  failure: string;
}
