import { environment } from '@env/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsModule } from '@ngxs/store';
import {
  CheckoutState,
  ParametersState,
  PaymentState,
  PlanSelectionState,
  TravelerDetailsState,
} from './features/checkout/store/states';

export const STATE_CONFIG = [
  NgxsModule.forRoot(
    [
      CheckoutState,
      PlanSelectionState,
      TravelerDetailsState,
      ParametersState,
      PaymentState,
    ],
    {
      developmentMode: !environment.production,
    }
  ),
  NgxsReduxDevtoolsPluginModule.forRoot(),
];
