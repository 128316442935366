import {
  GetPerksResponse as Perk,
  Plan,
} from '@app/features/checkout/interfaces';

function getDataLayer() {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer;
}

export function addPlanToCart(id: number, name: string, price: number): void {
  const dataLayer = getDataLayer();

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [
        {
          price: price,
          quantity: 1,
          item_id: id,
          item_name: name,
          item_brand: 'SPV',
          item_variant: '',
          item_category: 'Planes',
        },
      ],
    },
  });
}

export function purchase(): void {
  if (!localStorage.getItem('eventPurcharse')) return;

  const res = JSON.parse(localStorage.getItem('eventPurcharse') ?? '{}');

  const dataLayer = getDataLayer();
  dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: res.hashQuote,
      affiliation: 'Online Store',
      value: res.price_plan,
      currency: res.currency_plan,
      coupon: '',
      items: [
        {
          item_name: res.name_plan,
          item_id: res.id_plan,
          price: res.price_display_plan,
          item_brand: 'SPV',
          item_category: 'Planes',
          item_variant: res.fee_plan,
          quantity: 1,
        },
      ],
    },
  });

  localStorage.removeItem('eventPurcharse');
}

export function sendQuoteEvent(): void {
  if (
    localStorage.getItem('spv_sendQuoteEvent') &&
    window['submit_form_quote']
  ) {
    setTimeout(() => {
      const quote = JSON.parse(localStorage.getItem('spv_quote') ?? '{}');
      cotiza_gratis();
      submit_form_quote(quote);
      localStorage.removeItem('spv_sendQuoteEvent');
    }, 1000);
  }
}

export function togglePerk(isChecked: boolean, perk: Perk): void {
  if (!window.dataLayer) return;

  const event = perk.eventTagManager ?? '';
  const dataLayer = getDataLayer();

  dataLayer.push(
    ...[
      { ecommerce: null },
      {
        event: isChecked ? `remove_${event}` : event,
        ecommerce: {
          items: [
            {
              price: perk.isFree ? 0 : 9.99, // TODO: Change this
              item_id: perk.id,
              quantity: 1,
              item_name: perk.name,
              item_brand: 'SPV',
              item_variant: perk.name,
              item_category: 'Adicionales',
            },
          ],
        },
      },
    ]
  );
}

export function viewPlans(plans: Plan[]) {
  const dataLayer = getDataLayer();

  dataLayer.push(
    ...[
      { ecommerce: null },
      {
        event: 'view_item',
        ecommerce: {
          items: plans.map((plan) => ({
            item_name: plan.name,
            item_id: plan.id,
            price: plan.priceNow,
            item_brand: 'SPV',
            item_category: 'Planes',
            item_variant: '',
            quantity: 1,
          })),
        },
      },
    ]
  );
}
