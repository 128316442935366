export interface Plan {
  id: number;
  name: string;
  badge: string;
  image: string;
  coverage: number;
  pricePrevious: number;
  priceNow: number;
  summary: string[];
}
