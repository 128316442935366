export interface PayuCreateResponse {
  status: string;
  message: string;
  transactionId: string;
  amount: number;
  paymentMethod: string;
  timestamp: string;
  state: string;
  checkoutUrl: string;
  requestDictionary: RequestDictionary;
}

interface RequestDictionary {
  additionalProp1: string;
  additionalProp2: string;
  additionalProp3: string;
}
