import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { APP_BASE_HREF } from '@angular/common';

const routes: Routes = [
  {
    path: 'cotizar/pago-rechazado',
    title: 'Pago Rechazado',
    loadChildren: () =>
      import('./features/payment-declined/payment-declined.module').then(
        (m) => m.PaymentDeclinedModule
      ),
  },
  {
    title: 'Pago Pendiente',
    path: 'cotizar/pago-pendiente/:hashcode',
    loadChildren: () =>
      import('./features/payment-pending/payment-pending.module').then(
        (m) => m.PaymentPendingModule
      ),
  },
  {
    title: 'Gracias por tu compra',
    path: 'cotizar/confirmar-pago/:hashcode',
    loadChildren: () =>
      import(
        './features/payment-confirmation/payment-confirmation.module'
      ).then((m) => m.PaymentConfirmationModule),
  },
  {
    path: 'cotizar',
    loadChildren: () =>
      import('./features/checkout/checkout.module').then(
        (m) => m.CheckoutModule
      ),
  },
  // Fallback when no prior route is matched
  { path: '**', component: EmptyRouteComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }],
})
export class AppRoutingModule {}
