import { DLocalPaymentMethodCode } from '@app/features/checkout/interfaces';

export const PAYMENT_METHOD_CODES: DLocalPaymentMethodCode[] = [
  {
    alpha2Code: 'USA',
    currency: 'USD',
    dniType: 'CI',
    country: 'Estados Unidos',
    min: 5,
    max: 20,
    type: 'number',
    methods: [
      {
        label: 'Pago Efectivo',
        id: 'EF',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/EF.png',
      },
    ],
  },
  {
    alpha2Code: 'AR',
    currency: 'ARS',
    dniType: 'DNI/CUIT',
    country: 'Argentina',
    min: 7,
    max: 11,
    type: 'number',
    methods: [
      {
        label: 'Transferencia Bancaria',
        id: 'IO',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/NB.png',
      },
      {
        label: 'Pago Fácil',
        id: 'PF',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PF.png',
      },
      {
        label: 'Rapi Pago',
        id: 'RP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/RP.png',
      },
    ],
  },
  {
    alpha2Code: 'BO',
    currency: 'BOB',
    country: 'Bolivia',
    dniType: 'CI',
    min: 5,
    max: 20,
    type: 'number',
    methods: [
      {
        label: 'Banco Nacional de Bolivia',
        id: 'IO',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/IO.png',
      },
    ],
  },
  {
    alpha2Code: 'BR',
    currency: 'BRL',
    dniType: 'CPF/CNPJ',
    country: 'Brasil',
    min: 11,
    max: 14,
    type: 'number',
    methods: [
      {
        label: 'Boleto',
        id: 'BL',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/BL.png',
      },
      {
        label: 'Itau',
        id: 'I',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/I.png',
      },
      {
        label: 'Bradesco',
        id: 'B',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/B.png',
      },
      {
        label: 'Banco do Brasil',
        id: 'BB',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/BB.png',
      },
      {
        label: 'Caixa',
        id: 'CA',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/CA.png',
      },
      {
        label: 'Santander',
        id: 'SB',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/SB.png',
      },
    ],
  },
  {
    alpha2Code: 'CL',
    currency: 'CLP',
    dniType: 'CI/RUT',
    country: 'Chile',
    min: 8,
    max: 9,
    type: 'number',
    methods: [
      {
        label: 'WebPay',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Servipag',
        id: 'SP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/SP.png',
      },
      {
        label: 'BBVA',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Santander',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'ITAU',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Corpbanca',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'BCI-TBANC',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Banco Falabella',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Banco Estado',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Banco Bice',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Banco Security',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Banco Consorcio',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Banco Ripley',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'ScotiaBank',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
      {
        label: 'Coopeuch',
        id: 'WP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/WP.png',
      },
    ],
  },
  {
    alpha2Code: 'CO',
    currency: 'COP',
    dniType: 'CC',
    country: 'Colombia',
    min: 6,
    max: 10,
    type: 'number',
    methods: [
      {
        label: 'Efecty',
        id: 'EY',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/EY.png',
      },
      {
        label: 'Davivienda',
        id: 'DA',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/DA.png',
      },
      {
        label: 'PSE',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Baloto',
        id: 'BU',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/BU.png',
      },
      {
        label: 'Banco Agrario',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco Av Villas',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco Caja Social',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco Colpatria',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco Corpbanca S.A.',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco de Bogota',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco de Occidente',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco GNB Sudameris',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco Pichincha S.A.',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco Popular',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco Procredit',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Bancolombia',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Bancoomeva S.A.',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'BBVA Colombia S.A.',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Citibank',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Helm Bank S.A.',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
      {
        label: 'Banco Falabella',
        id: 'PC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PC.png',
      },
    ],
  },
  {
    alpha2Code: 'EC',
    currency: 'USD',
    dniType: 'CI',
    country: 'Ecuador',
    min: 5,
    max: 20,
    type: 'number',
    methods: [
      {
        label: 'Pago Efectivo',
        id: 'EF',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/EF.png',
      },
    ],
  },
  {
    alpha2Code: 'MX',
    currency: 'MXN',
    dniType: 'CURP',
    country: 'México',
    min: 10,
    max: 18,
    type: 'text',
    methods: [
      {
        label: 'OXXO',
        id: 'OX',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/OX.png',
      },
      {
        label: 'SPEI',
        id: 'SE',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/SE.png',
      },
      {
        label: 'BBVA Bancomer',
        id: 'BV',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/BV.png',
      },
      {
        label: 'Banorte',
        id: 'BQ',
        brand: 'https://assets.seguroparaviaje.com/img/pagos/Banorte.jpg',
      },
      {
        label: 'Santander',
        id: 'SM',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/SM.png',
      },
    ],
  },
  {
    alpha2Code: 'PY',
    currency: 'PYG',
    dniType: 'CI',
    country: 'Paraguay',
    min: 5,
    max: 20,
    type: 'number',
    methods: [
      {
        label: 'PagoExpress',
        id: 'PE',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/PE.png',
      },
    ],
  },
  {
    alpha2Code: 'PE',
    currency: 'PEN',
    dniType: 'DNI',
    country: 'Perú',
    min: 8,
    max: 9,
    type: 'number',
    methods: [
      {
        label: 'Pago Efectivo',
        id: 'EF',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/EF.png',
      },
      {
        label: 'BCP',
        id: 'BC',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/BC.png',
      },
      {
        label: 'Interbank',
        id: 'IB',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/IB.png',
      },
      {
        label: 'BBVA',
        id: 'BP',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/BP.png',
      },
    ],
  },
  {
    alpha2Code: 'UY',
    currency: 'UYU',
    dniType: 'CI',
    country: 'Uruguay',
    min: 6,
    max: 8,
    type: 'number',
    methods: [
      {
        label: 'Red Pagos',
        id: 'RE',
        brand: 'https://pay.dlocal.com/views/2.0/images/payments/RE.png',
      },
      // { label: 'Abitab', id: 'AI', brand: 'https://pay.dlocal.com/views/2.0/images/payments/AI.png' }
    ],
  },
];
