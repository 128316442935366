<div class="row mt-3">
  <div class="col-12 col-lg-8 mt-2">
    <label for="payerName" class="Gotham-bold fw-700 f-16 mb-1">
      {{ "Titular de la tarjeta" | translate }}
    </label>
    <input
      type="text"
      class="form-control input-payment Gotham fw-400 f-16"
      id="payerName"
      [formControl]="payerName"
    />
  </div>

  <div *ngIf="elementsOptions.clientSecret" class="col-12 col-lg-8 mt-2">
    <ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
      <ngx-stripe-payment />
    </ngx-stripe-elements>
  </div>

  <div class="col-12 mt-3">
    <app-purchase-action (onCheckout)="collectPayment()"></app-purchase-action>
  </div>
</div>
