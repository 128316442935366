export interface DLocalCashCreateResponse {
  status: string;
  message: string;
  transactionId: string;
  amount: number;
  paymentMethod: string;
  timestamp: string;
  dlocalTransactionId: string;
  redirectUrl: string;
  notificationUrl: string;
  dLocalResponse: DLocalResponse;
  dLocalResponseSerialized: string;
}

interface DLocalResponse {
  status: string;
  description: string;
  officialDocument: string;
  status_detail: string;
  redirect_url: string;
  order_id: string;
  id: string;
  code: string;
  message: string;
  amount: number;
  status_code: string;
  currency: string;
  country: string;
  payment_method_id: string;
  payment_method_type: string;
  payment_method_flow: string;
  payer: Payer;
  notification_url: string;
  created_date: string;
}

interface Payer {
  name: string;
  user_reference: string;
  email: string;
}
