export function calculateDiscountPercentage(
  previousPrice: number,
  currentPrice: number
): number {
  if (previousPrice <= 0) {
    throw new Error('Previous price must be greater than zero');
  }

  const discountPercentage =
    ((previousPrice - currentPrice) / previousPrice) * 100;
  return Math.round(discountPercentage);
}

export function isFunctionDefined(func: any): func is Function {
  return typeof func === 'function';
}

export const onlyLetters = (event: KeyboardEvent, ignore: string[] = []) => {
  const key = event.key;
  const letras = ' áéíóúabcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const especiales = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

  return letras.includes(key) || especiales.concat(ignore).includes(key);
};

export const onlyNumbers = (event: KeyboardEvent, ignore: string[] = []) => {
  const key = event.key;
  const numeros = '0123456789';
  const especiales = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete'];

  return numeros.includes(key) || especiales.concat(ignore).includes(key);
};

export const cleanString = (string: string): string => {
  // NOTE: No cambiar el orden
  return string
    .replace(/\s+/g, ' ') // Reemplazar múltiples espacios por un solo espacio
    .trim() // Eliminar espacios al inicio y al final
    .replace(/-+/g, '-') // Eliminar guiones consecutivos
    .replace(/^-|-$/g, ''); // Eliminar guiones al inicio y al final
};

export const cleanInputOfNames = (string: string): string => {
  return cleanString(string.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s-]/g, '')); // Eliminar todos los caracteres que no sean letras, espacios o guiones
};

export const cleanDniInput = (string: string): string => {
  return cleanString(string.replace(/[^a-zA-Z0-9\s-]/g, '')); // Eliminar todos los caracteres que no sean letras, espacios o guiones
};
