export interface MercadoPagoCreateResponse {
  status: string;
  message: string;
  transactionId: string;
  amount: number;
  paymentMethod: string;
  timestamp: string;
  mercadoPagoInitPoint: string;
  mercadoPagoResponse: string;
}
