import { Plan, PlanBenefitCompared } from '../../interfaces';

const PREFIX = '[PlanSelection]';

export namespace PlanSelection {
  export class SetPlans {
    static readonly type = `${PREFIX} Set Plans and Plan Benefits`;
    constructor(
      public plans: Plan[],
      public planBenefits: PlanBenefitCompared[]
    ) {}
  }

  export class SelectPlan {
    static readonly type = `${PREFIX} Select Plan`;
    constructor(public plan: Plan) {}
  }

  export class ShowDetails {
    static readonly type = `${PREFIX} Show Plan details`;
    constructor(public id: number) {}
  }
}
