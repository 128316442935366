import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { FooterComponent, HeaderComponent } from '@shared/components';

import { createTranslateLoader } from './services/i18n';
import { STATE_CONFIG } from './app-state.config';
import { INTERCEPTORS_PROVIDER } from './interceptors';
import { NgxStripeModule } from 'ngx-stripe';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AssetUrlPipe } from './pipes/asset-url/asset-url.pipe';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SWEETALERT_CONFIG } from './app-sweetalert';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxStripeModule.forRoot(),
    SweetAlert2Module.forRoot(SWEETALERT_CONFIG),
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule,
    ...STATE_CONFIG,
    AssetUrlPipe,
  ],
  providers: [...INTERCEPTORS_PROVIDER],
  bootstrap: [AppComponent],
})
export class AppModule {}
