import { Sweetalert2ModuleConfig } from '@sweetalert2/ngx-sweetalert2';

export const SWEETALERT_CONFIG: Sweetalert2ModuleConfig = {
  provideSwal: () =>
    import('sweetalert2').then(({ default: swal }) =>
      swal.mixin({
        // example: set global options here
        confirmButtonText: `Confirmer`,
        cancelButtonText: `Annuler`,
      })
    ),
};
