import Swal from 'sweetalert2';
import { GetPerksResponse, PET_PERK_TYPE } from '../interfaces';
import { CustomerForm } from '../models';
import { ErrorTopEndAlert } from '@app/helpers';

// They are in the order in which they must be validated.
const FORM_FIELED = [
  'name',
  'lastName',
  'officialDocument',
  'birthDate',
  'gender',
  'pet-type',
  'pet-name',
  'pet-petBreed',
  'pet-age',
];

export const scrollToFirstInvalidField = (
  customer: CustomerForm,
  index: number
): void => {
  let isFirstInvalidField = false;
  FORM_FIELED.forEach((field) => {
    if (isFirstInvalidField) return;
    if (customer.get(field.split('-'))?.invalid) {
      isFirstInvalidField = true;

      setTimeout(() => {
        const element = document.getElementById(`${field}-${index}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          element.focus();
        }
      }, 300);
    }
  });
};

export const hasPetPerk = (perks: GetPerksResponse[]) => {
  const perk = perks.find((p) => p.type == PET_PERK_TYPE);
  return perk ? { id: perk.id, isFree: perk.isFree ?? false } : null;
};

export const customersFormErrorAlert = (customers: string[]) =>
  ErrorTopEndAlert.fire({
    html:
      '<b>Recuerda completar la información.</b><br/>Los siguientes viajeros tienen datos incompletos: <br/><br/>*' +
      customers.join('<br/>*'),
  });
