export interface DirectopagoCashCreateResponse {
  status: string;
  message: string;
  transactionId: string;
  amount: number;
  paymentMethod: string;
  timestamp: string;
  url: string;
  directoPagoResponse: DirectoPagoResponse;
}

interface DirectoPagoResponse {
  status: number;
  url: string;
}
