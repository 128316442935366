<div class="container-fluid b-content">
  <div class="container">
    <div class="row">
      <div
        class="col-xs-12 col-12 col-md-12 d-flex align-items-center justify-content-between"
      >
        <img
          src="https://res.cloudinary.com/pervolare-org/image/upload/v1701117400/spv/sites/images/header/logo-mobile_fow8zc.png"
          alt="logo spv"
          width="160"
          height="71"
        />
        <a
          href="https://api.whatsapp.com/send?phone=12018016266&text=Buen%20d%C3%ADa,%20quisiera%20tener%20asesor%C3%ADa%20personalizada%20para%20mi%20seguro%20de%20viaje"
          target="_blank"
        >
          <img
            src="https://res.cloudinary.com/pervolare-org/image/upload/v1693514364/spv/sites/images/header/whatsapp_vdczcv.png"
            alt="logo whatsapp"
            width="34"
            height="34"
          />
        </a>
      </div>
    </div>
  </div>
</div>
