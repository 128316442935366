import {
  AbstractControl,
  FormArray,
  FormControl,
  ValidationErrors,
} from '@angular/forms';

export const DEFAULT_AGES = [
  {
    lowerLimit: 0,
    higherLimit: 69,
  },
  {
    lowerLimit: 70,
    higherLimit: 79,
  },
  {
    lowerLimit: 80,
    higherLimit: 85,
  },
  {
    lowerLimit: 86,
    higherLimit: 90,
  },
];

export function ValidateAges(
  control: AbstractControl
): ValidationErrors | null {
  if (control instanceof FormArray) {
    const allAreFormControls = control.controls.every(
      (c) => c instanceof FormControl
    );

    if (allAreFormControls) {
      const totalAge = control.controls.reduce(
        (acc, curr) => acc + (curr.value || 0),
        0
      );

      if (totalAge < 1) {
        return { invalidAge: true };
      }
    }
  }

  return null;
}
