export interface ServerPaymentMethod {
  id: number;
  name: string;
  type: number;
}

export interface PaymentMethod {
  img: string;
  icon: string;
  title: string;
  value: string;
  [key: string]: string;
}

export enum OrderPaymentStateEnum {
  Pending,
  Processing,
  Approved,
  Refused,
}
