import { PaymentEngineType } from '@app/interfaces';
import { OrderPaymentStateEnum } from './payment-component.interface';

export interface InsertCustomStateRequest {
  orderId: number;
  paymentId?: null | string;
  paymentResponse?: null | string;
  orderPaymentState: OrderPaymentStateEnum;
  paymentEngineType: PaymentEngineType;
}
