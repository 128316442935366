import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseActionComponent } from '../../purchase-action/purchase-action.component';
import { NgIf } from '@angular/common';
import { CheckoutState } from '@app/features/checkout/store/states';
import { PaymentComponent } from '../base-payment.component';
import { PaymentEngineType } from '@app/interfaces';
import { finalize } from 'rxjs';
import { ErrorAlert } from '@app/helpers';

@Component({
  selector: 'app-directopago-cash-payment',
  templateUrl: './directopago-cash-payment.component.html',
  standalone: true,
  imports: [TranslateModule, PurchaseActionComponent, NgIf],
})
export class DirectopagoCashPaymentComponent extends PaymentComponent {
  protected override paymentEngineType = PaymentEngineType.DirectoPago_Cash;

  public checkout() {
    if (this.isPaying()) return;
    this.setPayingStatus(true);

    const hashcode = this.store.selectSnapshot(CheckoutState.hashcode);
    const topLevelDomain = this.store
      .selectSnapshot(CheckoutState.domain)
      .toUpperCase();
    const { discountCode } = this.store.selectSnapshot(CheckoutState.details);

    this._service
      .directopagoCreate({
        backUrl: location.href,
        orderId: this.orderId(),
        country: topLevelDomain == 'OM' ? 'AR' : topLevelDomain,
        requestId: this.orderId() == 0 ? this.entityId() : 0,
        successUrl: `${origin}/cotizar/confirmar-pago/${hashcode}`,
        discountCode,
      })
      .pipe(finalize(() => this.setPayingStatus(false)))
      .subscribe({
        next: (response) => (location.href = response.url),
        error: ({ error }) => ErrorAlert.fire({ text: error.error.message }),
      });
  }
}
