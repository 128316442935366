import { Customer, GetQuoteRequest } from '../interfaces';

const PREFIX = '[Checkout]';

export namespace Checkout {
  export class GetTravelInsurance {
    static readonly type = `${PREFIX} Get Travel Insurance`;
    constructor(public payload: GetQuoteRequest, public skipSteps = true) {}
  }

  export class SetCurrency {
    static readonly type = `${PREFIX} Set Currency`;
    constructor(public currency: string) {}
  }

  export class SetHashcode {
    static readonly type = `${PREFIX} Set Hashcode`;
    constructor(public hashcode: string) {}
  }

  export class SetIsAdmin {
    static readonly type = `${PREFIX} Set Is Admin`;
    constructor(public isAdmin: boolean) {}
  }

  export class SubmitPlanSelection {
    static readonly type = `${PREFIX} Submit Plan Selection`;
    constructor(public planId: number) {}
  }

  export class SubmitTravelerDetails {
    static readonly type = `${PREFIX} Submit Traveler Details`;
    constructor(public customers: Customer[]) {}
  }

  export class NextStep {
    static readonly type = `${PREFIX} Next step`;
  }

  export class PreviusStep {
    static readonly type = `${PREFIX} Previus step`;
  }

  export class MoveToStep {
    static readonly type = `${PREFIX} Move to step`;
    constructor(public step: number) {}
  }

  export class UpdatePerks {
    static readonly type = `${PREFIX} Update Temporal Perks Request`;
    constructor(public perks: number[][]) {}
  }

  export class ReQuote {
    static readonly type = `${PREFIX} Re Quote Request`;
  }
}
