<form id="d-local-cash" [formGroup]="form" class="container mt-3 pb-4">
  <div class="row">
    <div class="col-12 px-0 mb-3">
      <p class="Gotham f-16 mb-0">
        {{ "Por favor confirmar la siguiente información:" | translate }}
      </p>
    </div>

    <div *ngIf="hiddeMerchantSelect" class="col-12 col-lg-10 px-0">
      <mat-label class="Gotham fw-700 f-16">
        {{ "País:" | translate }}
      </mat-label>
      <mat-form-field class="w-100 px-0 mt-1" appearance="outline">
        <mat-select
          placeholder="Seleccione método de pago"
          (selectionChange)="selectMerchant($event.value)"
        >
          <mat-option>
            {{ "Seleccione un país" | translate }}
          </mat-option>

          <mat-option *ngFor="let payment of merchants" [value]="payment">
            {{ payment.country }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="merchant" class="row">
    <div class="col-12 col-md-6 col-lg-5 ps-0">
      <mat-label for="dlocal-cash-dni" class="Gotham fw-700 f-16">
        {{ "Documento" | translate }} ({{ merchant.dniType }}):
      </mat-label>
      <mat-form-field appearance="outline" class="w-100 px-0 mt-1">
        <input
          #dni
          matInput
          formControlName="dni"
          [type]="merchant.type"
          [minlength]="merchant.min"
          [maxlength]="merchant.max"
        />

        <mat-hint>
          {{ dni.value.length }}
          <span *ngIf="merchant.type == 'number'; else text">números</span>
          <ng-template #text>caracteres</ng-template>
        </mat-hint>

        <mat-hint align="end">
          Valor entre {{ merchant.min }} y {{ merchant.max }}
          <span *ngIf="merchant.type == 'number'; else text">números</span>
          <ng-template #text>caracteres</ng-template>
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="col-12 col-md-6 col-lg-5 pe-0">
      <mat-label class="Gotham fw-700 f-16">
        {{ "Método de pago:" | translate }}
      </mat-label>
      <mat-form-field appearance="outline" class="w-100 px-0 mt-1">
        <mat-select
          formControlName="bank"
          (selectionChange)="selectPaymentMethod($event.value)"
        >
          <mat-select-trigger>
            <img
              *ngIf="paymentMethod"
              width="25"
              [alt]="paymentMethod.label"
              [src]="paymentMethod.brand"
              class="img-fluid ml-2 rounded"
            />
            {{ paymentMethod?.label }}
          </mat-select-trigger>

          <mat-option
            *ngFor="let method of merchant.methods"
            [value]="method.id"
          >
            <img
              width="25"
              [alt]="method.label"
              [src]="method.brand"
              class="img-fluid ml-2 rounded"
            />
            {{ method.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="col-12 mt-2 ps-0">
      <app-purchase-action (onCheckout)="checkout()"></app-purchase-action>
    </div>
  </div>
</form>
