import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseActionComponent } from '../../purchase-action/purchase-action.component';
import { CurrencyPipe } from '@angular/common';
import { PaymentComponent } from '../base-payment.component';
import { PaymentEngineType } from '@app/interfaces';
import { Alert } from '@app/helpers';
import { CheckoutState } from '@app/features/checkout/store';

@Component({
  selector: 'app-zelle-payment',
  standalone: true,
  templateUrl: './zelle-payment.component.html',
  imports: [TranslateModule, PurchaseActionComponent, CurrencyPipe],
})
export class ZellePaymentComponent extends PaymentComponent {
  protected override paymentEngineType = PaymentEngineType.Zelle;

  public detail = this.store.selectSnapshot(CheckoutState.details);
  public currency = this.store.selectSnapshot(CheckoutState.currency);
  public readonly account = 'pagos@pervolare.com';
  public readonly verificationAccount = 'ventas@seguroparaviaje.com';

  public checkout() {
    Alert.fire({
      icon: 'info',
      html: `Enviar soporte de pago Zelle a: <b>${this.verificationAccount}</b>`,
    });
  }
}
