import { StateContext } from '@ngxs/store';
import { CheckoutModel } from '../store/models';
import { GetQuoteResponse, SelectPlanResponse } from '../interfaces';
import { PlanSelection, TravelerDetails } from '../store/actions';
import { ErrorAlert, getWhatsappLink } from '@app/helpers';

export const handleInitialResponse = (
  ctx: StateContext<CheckoutModel>,
  payload: any,
  skipSteps: boolean,
  res: GetQuoteResponse
) => {
  const { currentStep } = ctx.getState();

  ctx.patchState({
    isAdmin: payload.isAdmin,
    entityId: payload.entityId,
    detail: {
      price: res.price,
      origin: res.origin,
      destiny: res.destiny,
      perksPrice: res.perksPrice,
      arrivalDate: res.arrivalDate,
      travelLength: res.travelLength,
      discountCode: res.discountCode,
      departureDate: res.departureDate,
      discountPercentage: res.discountPercentage,
      priceWithoutModifiers: res.priceWithoutModifiers,
    },
    currentStep: skipSteps ? res.temporalData.stepIndex : currentStep,
  });
};

export const handlePlansAndCustomers = (
  ctx: StateContext<CheckoutModel>,
  res: GetQuoteResponse
) => {
  const currentPlan = res.plans.find((p) => p.id === res.planId);

  const actions = [
    currentPlan && new PlanSelection.SelectPlan(currentPlan),
    new PlanSelection.SetPlans(res.plans, res.planBenefits),
    new TravelerDetails.SetCustomers(res.temporalData.customers),
  ].filter(Boolean); // Filtra los valores null o undefined

  return ctx.dispatch(actions);
};

export const handlePlanSelectionResponse = (
  ctx: StateContext<CheckoutModel>,
  res: SelectPlanResponse
) => {
  const { detail } = ctx.getState();

  ctx.patchState({
    detail: {
      ...detail,
      price: res.price,
      perksPrice: res.perksPrice,
      discountPercentage: res.discountPercentage,
      priceWithoutModifiers: res.priceWithoutModifiers,
    },
  });
};

export const handleError = (error: any) => {
  console.log('🚀 ~ handleError ~ error:', error);
  if (error?.code === 1001) {
    location.href = '/cotizar/confirmar-pago/' + error.details;
  } else {
    ErrorAlert.fire({
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).finally(() => (location.href = getWhatsappLink()));
  }
};
