import { inject, Injectable, Type } from '@angular/core';
import {
  DirectopagoCashCreateRequest,
  DirectopagoCashCreateResponse,
  DLocalCashCreateRequest,
  DLocalCashCreateResponse,
  InsertCustomStateRequest,
  MercadoPagoCreateRequest,
  MercadoPagoCreateResponse,
  PaypalConfirmRequest,
  PaypalCreateRequest,
  PaypalCreateResponse,
  PayuCreateRequest,
  PayuCreateResponse,
  StripeCreateRequest,
  StripeCreateResponse,
} from '../interfaces';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs';
import { Store } from '@ngxs/store';
import { Payment } from '../store/actions';
import { PaymentComponent } from '../steps/fourth-step/payment-methods/base-payment.component';
import { PayuPaymentComponent } from '../steps/fourth-step/payment-methods/payu-payment/payu-payment.component';
import { ZellePaymentComponent } from '../steps/fourth-step/payment-methods/zelle-payment/zelle-payment.component';
import { PaypalPaymentComponent } from '../steps/fourth-step/payment-methods/paypal-payment/paypal-payment.component';
import { StripePaymentComponent } from '../steps/fourth-step/payment-methods/stripe-payment/stripe-payment.component';
import { PayuOthersPaymentComponent } from '../steps/fourth-step/payment-methods/payu-payment/payu-others-payment.component';
import { DLocalCashPaymentComponent } from '../steps/fourth-step/payment-methods/d-local-cash-payment/d-local-cash-payment.component';
import { MercadoPagoPaymentComponent } from '../steps/fourth-step/payment-methods/mercado-pago-payment/mercado-pago-payment.component';
import { DirectopagoCashPaymentComponent } from '../steps/fourth-step/payment-methods/directopago-cash-payment/directopago-cash-payment.component';
import { BaseResponse } from '@app/interfaces';

const routes = {
  payu: '/Payment/PayU',
  dlocal: '/Payment/Dlocal',
  stripe: '/Payment/Stripe/v2',
  paypal: '/Payment/Paypal',
  paypalConfirm: '/Payment/Paypal/Confirm',
  mercadoPago: '/Payment/MercadoPago',
  directopago: '/Payment/DirectoPago',
  insertCustomState: '/OrderPayment/InsertCustomState',
};

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {
  private http = inject(HttpClient);
  private store = inject(Store);
  private paymentMethodMap: { [key: string]: Type<PaymentComponent> } = {
    payu: PayuPaymentComponent,
    zelle: ZellePaymentComponent,
    stripe: StripePaymentComponent,
    paypal: PaypalPaymentComponent,
    mercadoPago: MercadoPagoPaymentComponent,
    'PayU2-otros': PayuOthersPaymentComponent,
    'd-local-efectivo': DLocalCashPaymentComponent,
    'directopago-efectivo': DirectopagoCashPaymentComponent,
  };

  getComponent(paymentMethod: string): Type<PaymentComponent> {
    return this.paymentMethodMap[paymentMethod];
  }

  insertCustomState(body: InsertCustomStateRequest) {
    return this.http.post<BaseResponse<void>>(routes.insertCustomState, body);
  }

  paypalCreate(body: PaypalCreateRequest) {
    return this.http
      .post<BaseResponse<PaypalCreateResponse>>(routes.paypal, body)
      .pipe(
        map((res) => res.result),
        tap(({ orderId }) =>
          this.store.dispatch(new Payment.SetOrderId(orderId))
        )
      );
  }
  paypalConfirm(body: PaypalConfirmRequest) {
    return this.http.post<BaseResponse<void>>(routes.paypalConfirm, body);
  }

  stripeCreate(body: StripeCreateRequest) {
    return this.http
      .post<BaseResponse<StripeCreateResponse>>(routes.stripe, body)
      .pipe(
        map((res) => res.result),
        tap(({ orderId, amount, clientSecret }) =>
          this.store.dispatch([
            new Payment.SetOrderId(orderId),
            new Payment.SetStripePayment(amount, clientSecret),
          ])
        )
      );
  }

  payuCreate(body: PayuCreateRequest) {
    return this.http
      .post<BaseResponse<PayuCreateResponse>>(routes.payu, body)
      .pipe(map((res) => res.result));
  }

  mercadoPagoCreate(body: MercadoPagoCreateRequest) {
    return this.http
      .post<BaseResponse<MercadoPagoCreateResponse>>(routes.mercadoPago, body)
      .pipe(map((res) => res.result));
  }

  directopagoCreate(body: DirectopagoCashCreateRequest) {
    return this.http
      .post<BaseResponse<DirectopagoCashCreateResponse>>(
        routes.directopago,
        body
      )
      .pipe(map((res) => res.result));
  }

  dlocalCreate(body: DLocalCashCreateRequest) {
    return this.http
      .post<BaseResponse<DLocalCashCreateResponse>>(routes.dlocal, body)
      .pipe(map((res) => res.result));
  }
}
