export type Alpha2Code =
  | 'USA'
  | 'US'
  | 'AR'
  | 'BO'
  | 'BR'
  | 'BZ'
  | 'CO'
  | 'CU'
  | 'CL'
  | 'EC'
  | 'GT'
  | 'HN'
  | 'MX'
  | 'NI'
  | 'PE'
  | 'PY'
  | 'UY';

export interface Currency {
  flag: string;
  code:
    | 'USD'
    | 'EUR'
    | 'ARS'
    | 'COP'
    | 'CUP'
    | 'BZD'
    | 'GTQ'
    | 'HNL'
    | 'CRC'
    | 'CLP'
    | 'NIO'
    | 'VES'
    | 'PAB'
    | 'MXN'
    | 'DOP'
    | 'SVC'
    | 'BOB';
  symbol: string;
  country: Alpha2Code;
}
