import Swal from 'sweetalert2';

export const Alert = Swal.mixin({
  color: '#2855a0',
  confirmButtonColor: '#2855a0',
});

export const ErrorAlertIcon = Swal.mixin({
  icon: 'error',
  color: '#0C1E4A',
  iconHtml:
    '<img src="https://res.cloudinary.com/pervolare-org/image/upload/v1724434765/spv/sites/images/checkout/popups/error-icon_af4usj.svg" />',
  showCloseButton: true,
});

export const ErrorAlert = ErrorAlertIcon.mixin({
  title: 'Oups! Algo salió mal.',
  text: 'Para recibir ayuda de nuestro equipo comercial da clic aquí:',
  customClass: { container: 'error-alert' },
  confirmButtonText:
    '<img src="https://res.cloudinary.com/pervolare-org/image/upload/v1724439006/spv/sites/images/checkout/popups/whatsapp-icon_tb3xgc.svg" /> WhatsApp',
  confirmButtonColor: '#38c14d',
});

export const ErrorTopEndAlert = ErrorAlertIcon.mixin({
  toast: true,
  timer: 10000,
  position: 'top-end',
  customClass: { container: 'error-top-end-alert' },
  showConfirmButton: false,
  didOpen: (toast) => {
    toast.onmouseenter = Swal.stopTimer;
    toast.onmouseleave = Swal.resumeTimer;
  },
});
