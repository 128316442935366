import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseActionComponent } from '../../purchase-action/purchase-action.component';
import { NgIf } from '@angular/common';
import { CheckoutState } from '@app/features/checkout/store/states';
import { PaymentComponent } from '../base-payment.component';
import { PaymentEngineType } from '@app/interfaces';
import { ErrorAlert } from '@app/helpers';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-mercado-pago-payment',
  templateUrl: './mercado-pago-payment.component.html',
  standalone: true,
  imports: [TranslateModule, PurchaseActionComponent, NgIf],
})
export class MercadoPagoPaymentComponent extends PaymentComponent {
  protected override paymentEngineType = PaymentEngineType.MercadoPago;

  public checkout() {
    if (this.isPaying()) return;
    this.setPayingStatus(true);

    const hashcode = this.store.selectSnapshot(CheckoutState.hashcode);
    const { discountCode } = this.store.selectSnapshot(CheckoutState.details);

    this._service
      .mercadoPagoCreate({
        orderId: this.orderId(),
        requestId: this.orderId() == 0 ? this.entityId() : 0,
        discountCode,
        backUrl: {
          failure: `${origin}/cotizar/pago-rechazado/${hashcode}`,
          pending: `${origin}/cotizar/pago-pendiente/${hashcode}`,
          success: `${origin}/cotizar/confirmar-pago/${hashcode}`,
        },
      })
      .pipe(finalize(() => this.setPayingStatus(false)))
      .subscribe({
        next: (response) => {
          if (response.status == 'success') {
            location.href = response.mercadoPagoInitPoint;
          }
        },
        error: ({ error }) => ErrorAlert.fire({ text: error.error.message }),
      });
  }
}
