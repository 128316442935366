<ngx-ui-loader [pbColor]="'#2855a0'"></ngx-ui-loader>
<!-- <div style="position: relative">
  <ngx-ui-loader
    [loaderId]="'http-bar-loader'"
    [hasProgressBar]="true"
    [pbColor]="'#2855a0'"
    [fgsColor]="'#2855a0'"
    [fgsSize]="200"
  ></ngx-ui-loader>
</div> -->

<app-header></app-header>

<div style="min-height: 80vh">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
