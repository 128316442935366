export enum PaymentEngineType {
  Paypal = 1,
  Stripe = 2,
  PayU = 5,
  DLocal_Cash = 6,
  MercadoPago = 7,
  PayU2_otros = 32,
  DirectoPago_Cash = 41,
  Zelle = 42,
}
