<div class="row mt-3">
  <div class="col-12">
    <p class="Gotham f-16 mb-0">
      {{ "Transfiere el monto de" | translate }}&nbsp;
      <span class="Gotham-bold"
        >{{
          detail.price + detail.perksPrice | currency : "USD" : currency.symbol
        }}&nbsp;{{ currency.code }}</span
      >&nbsp; {{ "a la cuenta" | translate }}&nbsp;<span class="Gotham-bold"
        >{{ account }}.</span
      >&nbsp;{{
        "Luego, envía tu comprobante para completar la compra. ¡Fácil y rápido!"
          | translate
      }}
    </p>
  </div>

  <div class="col-12">
    <app-purchase-action
      (onCheckout)="checkout()"
      [submitLabel]="'Enviar comprobante' | translate"
    ></app-purchase-action>
  </div>
</div>
