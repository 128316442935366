import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { PlanBenefit } from '../interfaces';
import { BaseResponse } from '@app/interfaces';

const routes = {
  planBenefits: '/TravelInsurancePlanBenefit/GetBenefitByPlan',
};

@Injectable({
  providedIn: 'root',
})
export class PlanSelectionService {
  private http = inject(HttpClient);

  fetchPlanBenefits(planId: number): Observable<PlanBenefit[]> {
    return this.http
      .get<BaseResponse<PlanBenefit[]>>(routes.planBenefits, {
        params: { planId },
      })
      .pipe(map(({ result }) => result));
  }
}
