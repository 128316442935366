import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GetPerksRequest, GetPerksResponse } from '../interfaces';
import { BaseResponse } from '@app/interfaces';
import { convertObjectValuesToString } from '@app/helpers';

const routes = {
  getByPlanAndDomain: '/TravelInsurancePlanPerk/GetByPlanAndDomain',
};

@Injectable({
  providedIn: 'root',
})
export class TravelInsurancePlanPerkService {
  private http = inject(HttpClient);

  fetchByPlanAndDomain(
    params: GetPerksRequest
  ): Observable<GetPerksResponse[]> {
    return this.http
      .get<BaseResponse<GetPerksResponse[]>>(routes.getByPlanAndDomain, {
        params: convertObjectValuesToString(params),
      })
      .pipe(
        map(({ result }) =>
          result
            .map((perk) => ({
              ...perk,
              summaries:
                typeof perk.summaries == 'string'
                  ? JSON.parse(perk.summaries)
                  : perk.summaries,
            }))
            .map((perk) => ({
              ...perk,
              summaries:
                perk.summaries[localStorage.getItem('language') ?? 'es'] ??
                perk.summaries,
            }))
        )
      );
  }
}
