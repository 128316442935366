import { Customer } from './customer.interface';
import { PlanBenefitCompared } from './plan-benefits.iterface';
import { Plan } from './plan.iterface';

export interface GetQuoteResponse {
  plans: Plan[];
  origin: Visa;
  planId: number;
  destiny: Visa;
  departureDate: string;
  arrivalDate: string;
  travelLength: number;
  temporalData: TemporalData;
  price: number;
  perksPrice: number;
  discountCode: string;
  planBenefits: PlanBenefitCompared[];
  currencyCode: string;
  discountPercentage: number;
  priceWithoutModifiers: number;
}

interface TemporalData {
  stepIndex: number;
  customers: Customer[];
}

interface Visa {
  id: number;
  name: string;
}
