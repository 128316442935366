import { Customer, GetPerksResponse, Perk } from '../../interfaces';

export interface TravelerDetailsModel {
  perks: GetPerksResponse[];
  customers: Customer[];
  form: {
    model: TravelerDetailsCustomerModel[];
    perks: Perk[][];
  };
}

export interface TravelerDetailsCustomerModel {
  name: string | null;
  lastName: string | null;
}
