import { Customer, Perk } from '../../interfaces';
import { TravelerDetailsCustomerModel } from '../models/traveler-details.model';

const PREFIX = '[TravelerDetails]';

export namespace TravelerDetails {
  export class SetCustomers {
    static readonly type = `${PREFIX} Set customers`;
    constructor(public customers: Customer[]) {}
  }
  export class UpdateFormValue {
    static readonly type = `${PREFIX} Update Form Value`;
    constructor(
      public index: number,
      public model: TravelerDetailsCustomerModel
    ) {}
  }
  export class UpdatePerksFormValues {
    static readonly type = `${PREFIX} Update Perks Form Values`;
    constructor(public perks: Perk[][]) {}
  }
  export class LoadPerks {
    static readonly type = `${PREFIX} Load Perks By Plan And domain`;
    constructor(public planId: number, public domainCode: string) {}
  }
  export class AddPerk {
    static readonly type = `${PREFIX} Add Perk`;
    constructor(public indices: number[], public perk: Perk) {}
  }
  export class RemovePerk {
    static readonly type = `${PREFIX} Remove Perk`;
    constructor(public indices: number[], public perkId: number) {}
  }
}
