import { Component, OnInit } from '@angular/core';
import { OrderPaymentStateEnum } from '@app/features/checkout/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { PurchaseActionComponent } from '../../purchase-action/purchase-action.component';
import {
  ICreateOrderRequest,
  IPayPalConfig,
  NgxPayPalModule,
} from 'ngx-paypal';
import { NgIf } from '@angular/common';
import { environment } from '@env/environment';
import { PlanSelectionState } from '@app/features/checkout/store/states';
import { PaymentComponent } from '../base-payment.component';
import { PaymentEngineType } from '@app/interfaces';
import { ErrorAlert } from '@app/helpers';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-paypal-payment',
  templateUrl: './paypal-payment.component.html',
  standalone: true,
  imports: [TranslateModule, PurchaseActionComponent, NgIf, NgxPayPalModule],
})
export class PaypalPaymentComponent extends PaymentComponent implements OnInit {
  protected override paymentEngineType = PaymentEngineType.Paypal;

  public config: IPayPalConfig | null = null;
  private plan = this.store.selectSnapshot(PlanSelectionState.currentPlan);

  ngOnInit(): void {
    this.disableAccordionPanels();
    this._ngxLoader.startLoader('payment-loader');

    this._service
      .paypalCreate({
        orderId: this.orderId(),
        requestId: this.orderId() == 0 ? this.entityId() : 0,
      })
      .subscribe({
        next: ({ amount, orderId }) => this.initConfig(amount, orderId),
        error: ({ error }) => {
          this.enableAccordionPanels();
          this._ngxLoader.stopLoader('payment-loader');
          ErrorAlert.fire({ text: error.error.message }).finally(() =>
            this.accordion.closeAll()
          );
        },
      });
  }

  private initConfig(amount: number, orderId: number): void {
    this.insertPaymentState(OrderPaymentStateEnum.Pending);
    this.config = {
      currency: 'USD',
      clientId: environment.paypalclientId,
      createOrderOnClient: (): ICreateOrderRequest => ({
        intent: 'CAPTURE',
        purchase_units: [
          {
            custom_id: orderId.toFixed(2),
            amount: {
              currency_code: 'USD',
              value: amount.toFixed(2),
              breakdown: {
                item_total: {
                  currency_code: 'USD',
                  value: amount.toFixed(2),
                },
              },
            },
            items: [
              {
                name: `Pago Seguro Para Viaje (${this.plan?.name})`,
                quantity: '1',
                category: 'DIGITAL_GOODS',
                unit_amount: {
                  currency_code: 'USD',
                  value: amount.toFixed(2),
                },
              },
            ],
          },
        ],
      }),
      advanced: { commit: 'true' },
      style: {
        label: 'paypal',
        layout: 'vertical',
      },
      onClientAuthorization: (data) => {
        this.setPayingStatus(true);

        const purchaseUnit = data.purchase_units[0];
        const paymentId = (purchaseUnit as any).payments?.captures[0]?.id;

        this._service
          .paypalConfirm({
            orderId: +(purchaseUnit.custom_id || this.orderId),
            paymentId,
            paymentResponse: JSON.stringify(data),
          })
          .pipe(finalize(() => this.setPayingStatus(false)))
          .subscribe({
            next: () => this.goToPaymentConfirmation(),
            error: () => {
              ErrorAlert.fire({
                text: 'No pudimos validar tu pago, intenta con otro medio de pago.',
              });
            },
          });
      },
      onCancel: (data) => {
        this.insertPaymentState(
          OrderPaymentStateEnum.Refused,
          JSON.stringify(data)
        );
        this.setPayingStatus(false);
      },
      onError: (error) => {
        this.insertPaymentState(
          OrderPaymentStateEnum.Refused,
          JSON.stringify(error)
        );
        ErrorAlert.fire({
          text: error.message,
        });
        this.setPayingStatus(false);
      },
      onClick: () => {
        // if (!this.isValidForm) this.showCompleteForm();
      },
    };

    this.enableAccordionPanels();
    this._ngxLoader.stopLoader('payment-loader');
  }
}
