export const environment = {
  production: false,
  defaultLanguage: 'es',
  supportedLanguages: ['es', 'en', 'pt'],
  tenantId: 2,
  apiUrl: 'https://spv-api-qa-temp.pervolare.net',
  apiToken:
    'c3B2LTItUEFnOVRmWjg5UVZqWjVZSDhVWkZoc3RrRHBHbmNkSkNxUUI4R2NTc1NxbXVrYjM0R0RBQVVETDlDNDdGN0Y4bg==',
  deployUrl: 'https://web-spv-qa-temp-microfrontend.pervolare.net/checkout',
  paypalclientId:
    'AUEGC40Wr_FSyz14zXUOHJznP_hJ0WxjByCI7X6PGXZxLrcS7WQGwACbt1_Qm9IhjpD2Z52gvBTGm7pr',
  stripeApiKey: 'pk_test_P2xwm8WTUHd2yWglxWl4BzqA',
};
