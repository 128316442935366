export interface PlanBenefitCompared {
  values: Value[];
  name: string;
  benefitValueId: number;
}

interface Value {
  planId: number;
  value: string;
}

export interface PlanBenefit {
  id: number;
  category: null;
  name: string;
  value: null;
  values: PlanBenefitValue[];
}

interface PlanBenefitValue {
  name: string;
  value: string;
  documentLanguageId: number;
  documentLanguage: null;
  isDeleted: boolean;
  id: number;
}
