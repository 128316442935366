export interface QuotationRequest {
  ages: number[];
  domain: string;
  requote: boolean;
  hashCode: string;
  arrivalDate: string;
  discountCode: string;
  originVisaId: number;
  destinyVisaId: number;
  departureDate: string;
}
