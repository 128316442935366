export interface GetPerksResponse {
  amount?: null | string;
  description?: null | string;
  eventTagManager?: null | string;
  id: number;
  imageUrl?: null | string;
  isFree?: boolean;
  name?: null | string;
  order?: number;
  summaries?: null | string;
  tenantId?: number;
  type?: number;
}
