import { inject, Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { CheckoutModel, DEFAULT_CHECKOUT_MODEL } from './models';
import { Checkout } from './checkout.action';
import { catchError, finalize, of, switchMap, tap } from 'rxjs';
import { Currency } from '../interfaces';
import { CheckoutService } from '../services';
import currencies from '../../../../assets/data/currencies.json';
import {
  handleError,
  handleInitialResponse,
  handlePlansAndCustomers,
  handlePlanSelectionResponse,
} from '../helpers';
import { scrollTop, toggleMainLoader } from '@app/helpers';

const CHECKOUT_STATE_TOKEN = new StateToken<CheckoutModel>('checkout');

@State<CheckoutModel>({
  name: CHECKOUT_STATE_TOKEN,
  defaults: {
    ...DEFAULT_CHECKOUT_MODEL,
    currencies: currencies as Currency[],
  },
})
@Injectable()
export class CheckoutState {
  private _checkout = inject(CheckoutService);

  @Selector()
  static isAdmin({ isAdmin }: CheckoutModel): boolean {
    return isAdmin;
  }

  @Selector()
  static entityId({ entityId }: CheckoutModel): number {
    return entityId ?? 0;
  }

  @Selector()
  static hashcode({ hashcode }: CheckoutModel): string {
    return hashcode;
  }

  @Selector()
  static currency(state: CheckoutModel): Currency {
    return (
      state.currencies.find((c) => c.code == state.currency) ??
      state.currencies[0]
    );
  }

  @Selector()
  static currentStep({ currentStep }: CheckoutModel): number {
    return currentStep;
  }

  @Selector()
  static details({ detail }: CheckoutModel) {
    return detail;
  }

  @Selector()
  static domain({ domain }: CheckoutModel) {
    return domain;
  }

  @Action(Checkout.GetTravelInsurance)
  getTravelinsuranceRequest(
    ctx: StateContext<CheckoutModel>,
    { payload, skipSteps }: Checkout.GetTravelInsurance
  ) {
    return this._checkout.getQuote(payload).pipe(
      tap((res) => handleInitialResponse(ctx, payload, skipSteps, res)),
      switchMap((res) => handlePlansAndCustomers(ctx, res)),
      catchError(({ error: { error } }) => of(handleError(error))),
      finalize(() => toggleMainLoader(true))
    );
  }

  @Action(Checkout.SetCurrency)
  setCurrency(ctx: StateContext<CheckoutModel>, payload: Checkout.SetCurrency) {
    ctx.patchState(payload);
    setCurrency(payload.currency);
  }

  @Action(Checkout.SetHashcode)
  setHashcode(ctx: StateContext<CheckoutModel>, payload: Checkout.SetHashcode) {
    ctx.patchState(payload);
  }

  @Action(Checkout.SetIsAdmin)
  setIsAdmin(ctx: StateContext<CheckoutModel>, payload: Checkout.SetIsAdmin) {
    ctx.patchState(payload);
  }

  @Action(Checkout.NextStep)
  stepperNext(ctx: StateContext<CheckoutModel>) {
    const currentStep = ctx.getState().currentStep;

    ctx.patchState({ currentStep: Math.min(currentStep + 1, 3) });
    scrollTop();
  }

  @Action(Checkout.PreviusStep)
  stepperPrev(ctx: StateContext<CheckoutModel>) {
    const currentStep = ctx.getState().currentStep;

    ctx.patchState({ currentStep: Math.max(currentStep - 1, 0) });
    scrollTop();
  }

  @Action(Checkout.MoveToStep)
  stepperTo(ctx: StateContext<CheckoutModel>, { step }: Checkout.MoveToStep) {
    if (step < 0 || step > 3) return;

    ctx.patchState({ currentStep: step });
    scrollTop();
  }

  @Action(Checkout.SubmitPlanSelection)
  onSubmitPlanSelection(
    ctx: StateContext<CheckoutModel>,
    { planId }: Checkout.SubmitPlanSelection
  ) {
    const { entityId: travelInsuranceRequestId, currency: currencyCode } =
      ctx.getState();

    return this._checkout
      .selectPlan({
        planId,
        currencyCode,
        travelInsuranceRequestId,
      })
      .pipe(tap((res) => handlePlanSelectionResponse(ctx, res))); // TODO: Catch error
  }

  @Action(Checkout.SubmitTravelerDetails)
  onSubmitTravelerDetails(
    ctx: StateContext<CheckoutModel>,
    { customers }: Checkout.SubmitTravelerDetails
  ) {
    const { entityId, isAdmin, domain } = ctx.getState();
    const perksSelected = customers.map((c) => c.perks.map((p) => p.id));

    return this._checkout.updateCustomers({
      domain,
      isAdmin,
      entityId,
      customers,
      perksSelected,
    }); // TODO: Catch error
  }

  @Action(Checkout.UpdatePerks)
  updatePerks(
    ctx: StateContext<CheckoutModel>,
    { perks }: Checkout.UpdatePerks
  ) {
    const { isAdmin, entityId, domain, currency } = ctx.getState();
    return this._checkout
      .updatePerks({
        domain,
        isAdmin,
        entityId,
        currencyCode: currency,
        perksSelected: perks,
      })
      .pipe(
        tap((price) =>
          ctx.patchState({
            detail: { ...ctx.getState().detail, perksPrice: price },
          })
        )
      );
  }
}
