const PREFIX = '[Payment]';

export namespace Payment {
  export class SetOrderId {
    static readonly type = `${PREFIX} Set Order Id`;
    constructor(public orderId: number) {}
  }

  export class SetPayingStatus {
    static readonly type = `${PREFIX} Set Paying Status`;
    constructor(public paying: boolean) {}
  }

  export class SetStripePayment {
    static readonly type = `${PREFIX} Set Stripe Payment`;
    constructor(public amount: number, public clientSecret: string) {}
  }
}
