<footer
  class="container-fluid text-center py-4 Gotham f-13"
  style="background-color: #13141c"
>
  <p class="text-white d-inline-block mb-md-0">
    {{ currentYear }} Seguro para Viaje | &nbsp;
  </p>
  <p class="text-white d-inline-block mb-md-0">
    All Rights Reserved <span class="d-none d-md-inline-block">|</span> &nbsp;
  </p>
  <p class="text-white d-inline-block mb-0 mb-md-0">
    <a class="text-white decoration-none" href="/politicas-privacidad">{{
      "Politicas de privacidad" | translate
    }}</a
    >&nbsp;| &nbsp;
  </p>
  <p class="d-inline-block mb-0 mb-md-0">
    <a
      class="text-white decoration-none"
      href="/servicios/terminos-y-condiciones-promocionales"
      >{{ "T&C Promocionales" | translate }}</a
    >
  </p>
</footer>
