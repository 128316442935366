import { Plan, PlanBenefit, PlanBenefitCompared } from '../../interfaces';

export interface PlanSelectionModel {
  plans: Plan[];
  current: Plan | null;
  planDetail: {
    plan: Plan;
    benefits: PlanBenefit[];
  } | null;
  planBenefits: PlanBenefitCompared[];
}
