export const PET_PERK_TYPE = 2;

export interface Customer {
  name: null | string;
  lastName: string | null;
  officialDocument: string | null;
  birthDate: string | null;
  age: number;
  isMainCustomer: boolean;
  email: null | string;
  perks: Perk[];
  pet: Pet;
  phoneNumber: null | string;
  gender: string | null;
}

export interface Perk {
  id: number;
  isFree: boolean;
}

export interface CustomerPerk {
  name: string | null;
  lastName: string | null;
  perks: Perk[];
}

interface Pet {
  name: string | null;
  chipNumber: string | null;
  type: string | null;
  age: number | null;
  petBreed: string | null;
  address: string | null;
}
