import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  signal,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, NgIf } from '@angular/common';
import { ErrorTopEndAlert } from '@app/helpers';

@Component({
  selector: 'app-purchase-action',
  templateUrl: './purchase-action.component.html',
  styleUrls: ['./purchase-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, NgIf, NgClass],
})
export class PurchaseActionComponent {
  @Input() submitLabel = 'Pagar';
  @Input() showCheckoutButton = true;
  @Output() onCheckout = new EventEmitter<void>();

  private store = inject(Store);

  public policyCheck = signal(false);
  public showInvalidPolicyCheck = signal(false);

  public contract: string = '';

  ngOnInit(): void {
    const spvTopLevelDomain = localStorage.getItem('spv_topLevelDomain');
    if (spvTopLevelDomain === 'co') {
      this.contract =
        'https://assets.seguroparaviaje.com/docs/condicionado-traveler-assistance-co.pdf';
    } else {
      this.contract =
        'https://assets.seguroparaviaje.com/docs/condicionado-traveler-assistance.pdf';
    }
  }

  public checkout(): void {
    if (!this.policyCheck()) {
      ErrorTopEndAlert.fire({
        text: 'Por favor, marca la casilla de términos y condiciones para finalizar la compra',
      });

      return this.showInvalidPolicyCheck.set(true);
    }

    this.onCheckout.emit();
  }

  public onPolicyCheckboxChange(): void {
    this.policyCheck.update((value) => {
      const newValue = !value;

      this.showInvalidPolicyCheck.set(!newValue);
      return newValue;
    });
  }
}
