import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { I18nService } from '@app/services/i18n';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _i18n = inject(I18nService);

  ngOnInit(): void {
    // Setup translations
    this._i18n.init(
      environment.defaultLanguage,
      environment.supportedLanguages
    );
  }

  ngOnDestroy(): void {
    this._i18n.destroy();
  }
}
