import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
  GetQuoteRequest,
  GetQuoteResponse,
  QuotationRequest,
  QuotationResponse,
  SelectPlanRequest,
  SelectPlanResponse,
  UpdateTemporalCustomersRequest,
  UpdateTemporalPerksRequest,
} from '../interfaces';
import { BaseResponse } from '@app/interfaces';
import { convertObjectValuesToString } from '@app/helpers';

const routes = {
  quote: '/Checkout/Quote',
  quotation: '/Quotation/Quote',
  selectPlan: '/Checkout/SelectPlan',
  updatePerks: '/Checkout/UpdateTemporalPerks',
  updateCustomers: '/Checkout/UpdateTemporalCustomers',
};

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  private http = inject(HttpClient);

  getQuote(params: GetQuoteRequest): Observable<GetQuoteResponse> {
    return this.http
      .get<BaseResponse<GetQuoteResponse>>(routes.quote, {
        params: convertObjectValuesToString(params),
      })
      .pipe(map(({ result }) => result));
  }

  quotation(body: QuotationRequest): Observable<QuotationResponse> {
    return this.http
      .post<BaseResponse<QuotationResponse>>(routes.quotation, body)
      .pipe(map(({ result }) => result));
  }

  selectPlan(body: SelectPlanRequest): Observable<SelectPlanResponse> {
    return this.http
      .post<BaseResponse<SelectPlanResponse>>(routes.selectPlan, body)
      .pipe(map(({ result }) => result));
  }

  updateCustomers(body: UpdateTemporalCustomersRequest) {
    return this.http
      .put<BaseResponse<void>>(routes.updateCustomers, body)
      .pipe(map(({ result }) => result));
  }

  updatePerks(body: UpdateTemporalPerksRequest) {
    return this.http
      .put<BaseResponse<number>>(routes.updatePerks, body)
      .pipe(map(({ result }) => result));
  }
}
