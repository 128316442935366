import { Alpha2Code } from './currency.interface';

export interface DLocalPaymentMethodCode {
  alpha2Code: Alpha2Code;
  currency: string;
  dniType: string;
  min: number;
  max: number;
  country: string;
  type: string;
  methods: DLocalPaymentMethod[];
}

export interface DLocalPaymentMethod {
  label: string;
  id: string;
  brand: string;
}
