import { StripeElementsOptions } from '@stripe/stripe-js';

export const ELEMENTS_OPTIONS: StripeElementsOptions = {
  locale: 'es',
  appearance: {
    theme: 'stripe',
    variables: {
      fontSizeSm: '16px',
      fontFamily: 'Gotham!important',
      colorPrimary: '#2855A0',
    },
    rules: {
      '.Label': {
        // fontSize: '16px',
        fontWeight: '700',
        // fontFamily: 'Gotham',
        lineHeight: '19.2px',
      },
      '.Input': {
        color: '#000000',
        height: '48px',
        border: '2px solid #E0E0E0',
        fontSize: '16px',
        boxShadow: '0px 2px 4px 0px #00000012',
        fontWeight: '400',
        lineHeight: '19.2px',
      },
      '.Input:focus': {
        outline: '0px',
        boxShadow: '0px 0px 4px #80b9ee',
        borderColor: 'hsla(218, 60%, 39%, 50%)',
      },
    },
  },
};
